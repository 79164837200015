<template>
  <v-container fluid>
    <instruction-list
      v-if="game.hasInstructions"
      :current-instruction="currentPlayer.currentInstruction"
      @finishGame="finishGame"
    />

    <!-- eslint-disable vue/no-v-html -->
    <div
      :class="{
        'locked-tangram': isLockedTangram,
        'reduced-svg-size': game.hasInstructions,
      }"
      class="ma-5 text-center"
      v-html="initialTangram"
    />
    <!-- eslint-enable vue/no-v-html -->
  </v-container>
</template>

<script>
import PlainDraggable from 'plain-draggable'
import _throttle from 'lodash/throttle'
import AVAILABLE_ACTIONS from '../availableActions'
import InstructionList from '../components/InstructionList'

export default {
  name: 'PlayingContainer',
  components: {
    InstructionList,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  inject: [
    'sendPlayerAction',
  ],
  data: () => ({
    hasChanges: false,
    initialTangram: null,
    isLockedTangram: false,
  }),
  computed: {
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
    saveProgressThrottled () {
      return _throttle(
        () => this.saveProgress({ avoidFetchData: true }),
        2500,
        { leading: true, trailing: true },
      )
    },
  },
  sockets: {
    async networkDiagramSessionFinished () {
      this.isLockedTangram = true
      this.saveProgress()
    },
  },
  created () {
    // Always keep the rendered player version
    this.initialTangram = this.currentPlayer.tangram
  },
  async mounted () {
    await this.$nextTick()
    const targetElementList = this.$el.querySelectorAll('[id^="target-"]')
    const formElementList = this.$el.querySelectorAll('[id^="form-"]')
    formElementList.forEach(formElement => {
      const plainDraggable = new PlainDraggable(formElement)
      plainDraggable.snap = {
        targets: Array.from(targetElementList),
        edge: 'inside',
      }
      plainDraggable.onDragEnd = () => this.saveProgressThrottled()
    })
  },
  methods: {
    saveProgress (options) {
      const tangram = this.$el.querySelector('#tangram').outerHTML
      return this.sendPlayerAction(AVAILABLE_ACTIONS.saveProgress, { tangram }, options)
    },
    async finishGame () {
      this.isLockedTangram = true
      await this.saveProgress({ avoidFetchData: true })
      await this.sendPlayerAction(AVAILABLE_ACTIONS.finishGame)
    },
  },
}
</script>

<style lang="scss">
  #tangram {
    max-height: calc(100vh - 64px - 71px)
  }
  .reduced-svg-size #tangram {
    max-height: calc(100vh - 64px - 71px - 170px);
  }
</style>

<style lang="scss" scoped>
  .locked-tangram {
    pointer-events: none;
    user-select: none;
  }
</style>
