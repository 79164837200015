<template>
  <v-card
    width="100%"
    class="blue-grey lighten-4"
    flat
    height="200px"
  >
    <v-card-text class="fill-height pa-0">
      <v-row
        ref="scrollArea"
        no-gutters
        align="center"
        class="fill-height flex-nowrap"
        style="overflow-x: scroll"
      >
        <template v-for="instruction in visibleInstructionList">
          <v-col
            :key="`instruction-${instruction.order}`"
            cols="auto"
            class="fill-height pa-3"
          >
            <v-img
              :src="instruction.src"
              class="fill-height rounded"
              style="width: 235px"
            />
          </v-col>

          <v-col
            v-if="instruction.order < visibleInstructionList.length"
            :key="`arrow-${instruction.order}`"
            cols="auto"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-col>
        </template>
        <v-col cols="auto">
          <v-btn
            v-if="hasNextInstruction"
            :loading="loadingNextInstruction"
            :disabled="loadingNextInstruction"
            color="accent"
            class="py-6 mr-5"
            style="white-space: pre-wrap; max-width: 130px"
            @click="nextInstruction"
            v-text="$t('networkdiagram.playing.nextInstruction')"
          />

          <ag-confirm-dialog
            v-else
            :title="$t('general.confirmation')"
            :message="$t('networkdiagram.playing.validateConfirm')"
            :cancel-label="$t('general.cancel')"
            :confirm-label="$t('general.validate')"
            validation-btn-color="accent"
            @confirm="finishGame"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="loadingFinish"
                :disabled="loadingFinish"
                color="accent"
                class="mr-5"
                v-bind="attrs"
                v-on="on"
                v-text="$t('general.validate')"
              />
            </template>
          </ag-confirm-dialog>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Repositories from 'src/repositories'
import Helpers from 'src/utils/helpers'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import AVAILABLE_ACTIONS from 'src/modules/network-diagram/availableActions'

export default {
  name: 'InstructionList',
  components: {
    AgConfirmDialog,
  },
  props: {
    currentInstruction: {
      type: Number,
      required: true,
    },
  },
  inject: [
    'sendPlayerAction',
  ],
  data () {
    return {
      internalCurrentInstruction: this.currentInstruction,
      instructionList: [],
      loadingFinish: false,
      loadingNextInstruction: false,
    }
  },
  computed: {
    visibleInstructionList () {
      return this.instructionList.slice(0, this.internalCurrentInstruction)
    },
    hasNextInstruction () {
      return this.internalCurrentInstruction < this.instructionList.length
    },
  },
  watch: {
    async internalCurrentInstruction () {
      await this.$nextTick()
      this.scrollInstruction()
    },
  },
  async created () {
    this.instructionList = await Repositories.networkdiagramInstruction.getList()
  },
  async mounted () {
    await Helpers.timeout(150)
    this.scrollInstruction()
  },
  methods: {
    async nextInstruction () {
      this.loadingNextInstruction = true
      await this.sendPlayerAction(
        AVAILABLE_ACTIONS.nextInstruction,
        { instruction: this.internalCurrentInstruction + 1 },
        { avoidFetchData: true },
      )
      this.internalCurrentInstruction += 1
      this.loadingNextInstruction = false
    },
    async finishGame () {
      this.loadingFinish = true
      this.$emit('finishGame')
    },
    scrollInstruction () {
      this.$refs.scrollArea.scrollTo({
        left: this.$refs.scrollArea.scrollWidth,
        behavior: 'smooth',
      })
    },
  },
}
</script>
