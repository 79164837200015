<template>
  <v-dialog
    v-model="opened"
    persistent
    max-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>
    <v-card>
      <v-card-title class="headline justify-center" v-text="title" />
      <v-card-text class="text-center" v-text="message" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="disabled"
          :loading="loadingCancel"
          color="primary"
          outlined
          @click="cancel"
          v-text="cancelLabel"
        />
        <v-btn
          ref="confirmBtn"
          :disabled="disabled"
          :loading="loadingConfirm"
          :color="validationBtnColor"
          @click="confirm"
          v-text="confirmLabel"
        />
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AgConfirmDialog',
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    cancelLabel: {
      type: String,
      default: 'Cancel',
    },
    confirmLabel: {
      type: String,
      default: 'Confirm',
    },
    validationBtnColor: {
      type: String,
      default: 'primary',
    },
  },
  data: () => ({
    opened: false,
    loadingConfirm: false,
    loadingCancel: false,
  }),
  computed: {
    disabled () {
      return this.loadingConfirm || this.loadingCancel || !this.opened
    },
  },
  watch: {
    async opened () {
      if (this.opened) {
        await this.$nextTick()
        this.$refs.confirmBtn.$el.focus()
      }
    },
  },
  methods: {
    close () {
      this.opened = false
    },
    async cancel () {
      this.loadingCancel = true
      if (this.$listeners.cancel) {
        await this.$listeners.cancel()
      }
      this.close()
      this.loadingCancel = false
    },
    async confirm () {
      this.loadingConfirm = true
      if (this.$listeners.confirm) {
        await this.$listeners.confirm()
      }
      this.close()
      this.loadingConfirm = false
    },
  },
}
</script>
